<template>
  <div class="bbr-promo--list">
    <v-simple-table>
      <thead>
        <tr>
          <th v-if="sorting"></th>
          <th width="10%">Promo Image</th>
          <th>Promo Title</th>
          <th v-if="!sorting">Promo Description</th>
          <th>Promo Featured Date</th>
        </tr>
      </thead>

      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
        infinite-scroll-distance="50"
        id="dragTable"
      >
        <tr
          v-for="promo in list"
          :key="promo.id"
          class="clickable new-tab-link-row"
          :class="{ 'sortableRow handle sortHandle': sorting }"
          v-list-link="setListLink(promo.id)"
        >
          <td v-if="sorting">
            <v-icon> {{ icons.drag }} </v-icon>
          </td>
          <td>
            <v-avatar :size="60" tile>
              <v-img :src="promo.image.url" />
            </v-avatar>
          </td>
          <td>
            {{ promo.title }}
            <v-icon
              v-if="promo.is_featured"
              small
              color="var(--v-primary-base)"
            >
              {{ icons.starCircle }}
            </v-icon>
          </td>
          <td v-if="!sorting">
            {{ promo.description }}
          </td>
          <td>
            {{ promo.featured_at | formatDate('YYYY-MM-DD') }}
          </td>
        </tr>
      </tbody>

      <table-list-loader v-if="isLoading" column="4" row />
    </v-simple-table>

    <no-list v-if="!hasPromoList && !isLoading" details="No Available Promos" />
  </div>
</template>

<script>
import TableListLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'
import { mdiDrag, mdiStarCircle } from '@mdi/js'
import Sortable from 'sortablejs'

import { mapGetters } from 'vuex'

export default {
  name: 'PromoListTable',

  props: {
    sorting: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    infiniteScroll,
  },

  components: {
    TableListLoader,
    NoList,
  },

  data() {
    return {
      icons: {
        drag: mdiDrag,
        starCircle: mdiStarCircle,
      },
      reorderedPromos: [],
    }
  },

  computed: {
    ...mapGetters({
      promos: 'promos/promos',
      isLoading: 'promos/isLoading',
      hasPromoList: 'promos/hasPromoList',
    }),

    list() {
      return this.promos.list
    },

    meta() {
      return this.promos.meta
    },

    lastPage() {
      return !this.meta.to
    },

    disableListScroll() {
      return this.isLoading || this.lastPage
    },
  },

  methods: {
    loadMore() {
      if (this.disableListScroll) return

      this.$emit('load', this.meta.current_page + 1)
    },

    setListLink(promoId) {
      return {
        id: promoId,
        routeName: 'promo.info',
        fullPath: `/promos/${promoId}/details`,
      }
    },

    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.promos.list.splice(oldIndex, 1)[0]
      this.promos.list.splice(newIndex, 0, movedItem)
    },
  },

  mounted() {
    /* eslint-disable no-new */
    new Sortable(document.querySelector('#dragTable'), {
      draggable: '.sortableRow',
      handle: '.sortHandle',
      onEnd: this.dragReorder,
    })
  },
}
</script>
