<template>
  <div class="bbr-promo">
    <main-app-bar>
      <template v-slot:title> Promos </template>

      <template v-slot:top-actions>
        <v-btn
          :to="{ name: 'new.promo' }"
          class="bg-primary-gradient primary ml-auto"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Promo
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-12 mb-10">
      <v-row>
        <v-col cols="12">
          <div class="form-container d-flex align-center">
            <div class="search-form">
              <v-text-field
                v-model="search"
                label="Search promo title or description"
                style="width:400px"
                @input="onPromoSearch"
                hide-details
                clearable
                rounded
                solo
                flat
              />
            </div>
            <span class="ml-3">
              <v-btn
                v-if="!promosReordering"
                @click="enablePromosReorder"
                depressed
              >
                <v-icon>{{ icons.filter }}</v-icon>
                <span>Reorder</span>
              </v-btn>
              <v-btn
                v-if="promosReordering && promosReordered"
                v-ripple="{ center: true }"
                color="success"
                @click="savePromosReorder"
                text
              >
                Save Changes
              </v-btn>
              <v-btn
                v-if="promosReordering"
                @click="cancelPromosReorder"
                color="error"
                text
              >
                Cancel
              </v-btn>
            </span>
          </div>
        </v-col>

        <v-col cols="12">
          <promo-list @load="fetchPromos" :sorting="promosReordering" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import PromoList from '../components/PromoList'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { debounce, mapValues } from 'lodash'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'PromoListPage',

  components: {
    MainAppBar,
    PromoList,
  },

  data() {
    return {
      search: this.$route.query.search,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      promosReordering: (state) => state.promos.promos.reordering,
      promos: (state) => state.promos.promos.list,
    }),
    ...mapGetters({
      hasPromoList: 'promos/hasPromoList',
      promosReordered: 'promos/promosReordered',
    }),
  },

  created() {
    if (!this.hasPromoList) {
      this.fetchPromos()
    }
  },

  methods: {
    ...mapActions({
      fetchPromolist: 'promos/getPromos',
      reorderPromos: 'promos/reorderPromos',
    }),

    ...mapMutations({
      clearPromoList: 'promos/clearPromoList',
      enablePromosReorder: 'promos/enablePromosReorder',
      cancelPromosReorder: 'promos/cancelPromosReorder',
    }),

    fetchPromos(page = 1) {
      this.fetchPromolist({ search: this.search, limit: 50, page })
    },

    onPromoSearch(term) {
      this.search = term
      this.searchOnPromos()

      this.$router.replace({ name: 'promos', query: { search: term } })
    },

    searchOnPromos: debounce(function() {
      this.clearPromoList()
      this.fetchPromos()
    }, 600),

    async savePromosReorder() {
      let promoIds = mapValues(this.promos, 'id')
      await this.reorderPromos(promoIds)
      this.cancelPromosReorder(false)
    },
  },
}
</script>
